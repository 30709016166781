import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { makeStyles, Box, InputLabel, ListItemIcon, ListItemText, MenuItem, FormControl, Select, Checkbox, NativeSelect } from '@material-ui/core';





const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250
        }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: "bottom",
        horizontal: "center"
    },
    transformOrigin: {
        vertical: "top",
        horizontal: "center"
    },
    variant: "menu"
};



const MultipleSelect = ({
    label,
    options,
    values,
    onChange
}) => {

    const classes = useStyles();


    const { t } = useTranslation();

    const [selected, setSelected] = useState(() => {
        if(values) return values
        return []
    });

    useEffect(() => {
        onChange(selected)
    }, [selected])


    const isAllSelected =
        options?.length > 0 && selected?.length === options?.length;

    const handleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
            console.log(selected?.length === options?.length ? [] : options)
            setSelected(selected?.length === options?.length ? [] : options);
            return;
        }
        setSelected(value);
    };


    return (
        <Box className={classes.root}>
            <FormControl className={classes.formControl}>
                <InputLabel id={label}>
                    {label}
                </InputLabel>
                <Select
                    variant="standard"
                    labelId={label}
                    multiple
                    value={selected}
                    onChange={handleChange}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                >
                    <MenuItem
                        value='all'
                        classes={{
                            root: isAllSelected ? classes.selectedAll : ""
                        }}
                    >
                        <ListItemIcon>
                            <Checkbox
                                classes={{ indeterminate: classes.indeterminateColor }}
                                checked={isAllSelected}
                                indeterminate={
                                    selected?.length > 0 && selected?.length < options.length
                                }
                            />
                        </ListItemIcon>
                        <ListItemText
                            classes={{ primary: classes.selectAllText }}
                            primary={t('select all')}
                        />
                    </MenuItem>
                    {options.map((option) => (
                        <MenuItem key={option} value={option}>
                            <ListItemIcon>
                                <Checkbox checked={selected?.indexOf(option) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={option} />
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>

    );
}

export default MultipleSelect;

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: 300
    },
    indeterminateColor: {
        color: "#f50057"
    },
    selectAllText: {
        fontWeight: 500
    },
    selectedAll: {
        backgroundColor: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.08)"
        }
    }
}));