const Styles = theme => ({
    main: {
        maxWidth: '100vw',
        height: '100vh',
        padding: '0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexDirection: 'column'
    },
    header: {
        maxWidth: '100vw',
        display: 'flex',
        position: 'relative',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#fafafa',
        padding: '0 50px',
        boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.05)',
        [theme?.breakpoints?.down('sm')]: {
            padding: '0 16px',
        }
    },
    content: {
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: '0 50px',
        [theme?.breakpoints?.down('sm')]: {
            padding: '0 8px',
        }
    },
    contentHeader: {
        height: 'auto',
        padding: '20px 0px',
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: '57px',
        alignItems: 'center',
        [theme?.breakpoints?.down('sm')]: {
             marginBottom: '20px',
        }
    },
    titleBox: {
        marginLeft: 'auto',
        marginRight: 'auto',
        '& h1': {
            marginLeft: '77px',
        },
         [theme?.breakpoints?.down('sm')]: {
           '& h1': {
                marginLeft: '0',
                fontSize: '32px'
        },
        }
    },
    logo: {
        width: '148px',
        height: '38px',
        marginBottom: 'auto',
        marginTop: 'auto',
        '& img': {
            width: '100%',
            height: '100%',
        },
        [theme?.breakpoints?.down('sm')]: {
            // display: 'none'
        }
    },
    form: {
        width: '100%'
    },
    paper: {
         maxWidth: '320px',
        padding: '20px 50px 40px',
        borderRadius: '20px',
        backgroundColor: '#fafafa',
        [theme?.breakpoints?.down('sm')]: {
             padding: '15px 12px 40px',
        }
    },
   title: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginBottom: '20px',
        fontFamily: 'Open Sans',
        fontSize: '24px',
        fontWeight: 'bold'
    },
    subtitle: {
        textAlign: 'center',
        height: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        fontFamily: 'Open Sans',
        fontSize: '16px',
    },
    subtext: {
        display: 'flex',
        justifyContent: 'flex-start',
        ...theme?.typography?.label,
    },
    anchor: {
        cursor: 'pointer',
        justifyContent: 'flex-start',
        ...theme?.typography?.anchor,
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: theme?.palette?.scheme?.accent?.main
    },
    progress: {
        color: theme?.palette?.scheme?.accent?.main
    },
     footer: {
        margin: '0 0 60px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& span': {
            fontSize: '12px',
             margin: '0px 0 6px'
         },
           [theme?.breakpoints?.down('sm')]: {
             padding: '15px 12px 40px',
        }
    },
    cycText: {
        fontFamily: 'Open Sans',
        color: '#d0212a',
        fontWeight: 'bold'
    },
     cycPrivacy: {
        fontFamily: 'Open Sans',
        color: '#000000',
          '& a': {
             color: '#000000',
             fontFamily: 'Open Sans',
             fontSize: '12px',
             textDecoration: 'none'
        }
    },
      cycWebsite: {
        fontFamily: 'Open Sans',
        color: '#000000'
    },
        bulletBox: {
          [theme?.breakpoints?.down('sm')]: {
             display: 'none'
        }
     }
})

export default Styles;