const Styles = theme => ({
    progressContainer: {
        flex: '1', 
        height: '300px', 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center'
    },
    label: {
        width: '100%',
        height: 'auto',
        fontSize: '27px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...theme.typography.subtitle,
    },
    cardAssociate: {
        width: '250px',
        height: '250px',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        margin: '0px 30px 30px 0px',
        backgroundColor: '#ffffff',
        boxShadow: '1px 1px 10px #EEEEEE',
        borderRadius: '7px',
        '&:hover': {
            '& $overlay': {
                display: 'flex',
            }
        },
        [theme?.breakpoints?.between('0', '600')]: {
            width: '100%',
            height: '250px',
        }
    }
})

export default Styles;







