import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress } from "@material-ui/core";


const SubmitButton = (props) => {

  const classes = useStyles();

  const renderButtonContent = () => {
    if (props.isSubmitting) {
      return (
        <CircularProgress size={24} color={'inherit'} />
      )
    }
    return props.label
  }


  return (
    <Button
      className={classes.root}
      size="large"
      variant="contained"
      color="primary"
      type="submit"
      style={props.style}
      disabled={props?.disabled}
    >
      {renderButtonContent()}
    </Button>
  );
}

export default SubmitButton

const useStyles = makeStyles({
  root: {
    margin: '8px 0 12px !important',
    width: '100%'
  }
});

