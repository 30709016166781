import React from 'react';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';





const CardPlaceholder = () => {

    const classes = useStyles();

    const navigate = useNavigate()

    return (

        <Box className={classes.root} onClick={() => {
            navigate('/bo/form')
        }}>
            <PlaylistAddIcon className={classes.icon}/>
        </Box>
    )
}

export default CardPlaceholder;


const useStyles = makeStyles(theme => ({
    root: {
        width: '250px',
        height: '250px',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        margin: '0px 30px 30px 0px',
        backgroundColor: theme.palette.primary.main,
        boxShadow: '1px 1px 10px #EEEEEE',
        borderRadius: '7px',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: theme.palette.primary.light,
        }
    },
    icon: {
        width: '36px',
        height: '35px'
    }
}));
