const Styles = theme => ({
    root: {
        width: '250px',
        height: '250px',
        display: 'flex',
        position: 'relative',
        overflow: 'hidden',
        margin: '0px 30px 30px 0px',
        backgroundColor: '#ffffff',
        boxShadow: '1px 1px 10px #EEEEEE',
        borderRadius: '7px',
        '&:hover': {
            '& $overlay': {
                display: 'flex',
            }
        },
        [theme?.breakpoints?.between('0', '600')]: {
            width: '100%',
            height: '250px',
        }
    },
    content: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    thumbnail: {
        flex: 1,
        overflow: 'hidden',
        backgroundColor: '#f2f2f2',
        '& img': {
            width: '100%',
            objectFit: 'contain',
        }
    },
    title: {
        flex: 0.3,
        padding: '5px 15px 0px 15px',
        display: 'flex',
        alignItems: 'center',
        '& p': {
            fontSize: '18px',
            fontWeight: 500,
            color: '#474747',
            margin: 0,
            display: '-webkit-box',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
    },
    subtitle: {
        display: 0.3,
        padding: '0px 15px 0px 15px',
        '& p': {
            fontSize: '14px',
            fontWeight: 300,
            lineHeight: '15px',
            color: '#474747',
            margin: 0,
            display: '-webkit-box',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
    },
    description: {
        flex: 0.6,
        display: 'flex',
        padding: '0px 15px 15px 15px',
        '& p': {
            fontSize: '14px',
            fontWeight: 200,
            lineHeight: '17px',
            color: '#B3B3B3',
            margin: 0,
            display: '-webkit-box',
            '-webkit-line-clamp': 3,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
    },
    buttons: {
        width: '35px',
        height: '35px',
        margin: '0px 3px',
        borderRadius: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: theme?.palette?.scheme?.accent?.main
    },
    icons: {
        fontSize: '19px',
        color: '#ffffff'
    },
    overlay: {
        width: '100%',
        height: '100%',
        display: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        backgroundColor: 'rgba(242,242,242, 0.7)',
    },
    toogleBox: {
        zIndex: 100,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    }
})

export default Styles;