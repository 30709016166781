const Styles = theme => ({
    root: {
        flex: 1,
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center',
        flexDirection: 'column',
        position: 'relative',
        marginBottom: '30px'
    },
    header: {
        width: '100%',
        height: '50px',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    line: {
        flex: 1,
        display: 'flex', 
        height: '1px', 
        margin: '0px 70px',
        backgroundColor: '#e3e3e3',
        [theme?.breakpoints?.between('0', '600')]: {
            display: 'none',
        }
    },
    content: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'center',
        color: '#d3d3d3',
        overflow: 'hidden',
        [theme?.breakpoints?.between('0', '600')]: {
            flexDirection: 'column',
        }
    },
    title: {
        display: 'flex',
        padding: '10px 0px',
        alignItems: 'center',
        ...theme?.typography?.title_h1,
        fontSize: '25px',
        fontWeight: '200',
        '& p': {
            margin: 0,
            display: '-webkit-box',
            '-webkit-line-clamp': 1,
            '-webkit-box-orient': 'vertical',
            overflow: 'hidden',
        },
    },
    actions: {
        display: 'flex'
    },
    buttons_round: {
        width: '35px',
        height: '35px',
        margin: '0px 3px',
        borderRadius: '35px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: theme?.palette?.scheme?.accent?.main
    },
    buttons: {
        width: '35px',
        height: '35px',
        margin: '0px 3px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
    },
    icons: {
        fontSize: '19px',
        color: '#ffffff'
    },
    icons_invert: {
        fontSize: '19px',
        transition: 'all 0.2s linear'
    },
    overlay: {
        width: '102%',
        height: '102%',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        borderRadius: '10px',
        backgroundColor: 'rgba(242,242,242, 0.7)',
    }
})

export default Styles;