import { LibraryBooksOutlined, PostAdd } from '@material-ui/icons';

import List from './Views/List';

export const Structer = {
    title: 'todos-page-title',
    sections: [
        {
            id: 'list',
            index: 0,
            label: 'todos-nav-list',
            icon: <LibraryBooksOutlined />,
            path: '/bo/todos/list',
            component: <List />,
        },
        {
            id: 'example',
            index: 1,
            label: 'todos-nav-example',
            icon: <PostAdd />,
            path: '/bo/todos/example',
            component: <>
                <h1>To implement new view</h1>
                <h5>You can create new view to this tab</h5>
            </>,
        }
    ],
}