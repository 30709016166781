import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { useNavigate } from "react-router";
import { makeStyles, Box, Container, Button, Paper, TextField } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EmailIcon from '@material-ui/icons/AlternateEmail';

import TextFields from '@material-ui/icons/TextFields';
import SubmitButton from "../../../../components/Backoffice/SubmitButton";
import MultipleSelect from "../../../../components/Backoffice/MultipleSelect";

import { ServiceContext } from '../../../../context/services/service-context';



const Create = () => {
    const classes = useStyles();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { GroupAndUser } = useContext(ServiceContext);

    const [listGroups, setListGroups] = useState([]);



     useEffect(() => {

        const fetchGroups = async () => {
            try {
                const result = await GroupAndUser.listGroup();
                const _groups = result?.data?.listGroup?.items
                const _groupsName = _groups.map(it => it.groupName)
                setListGroups(_groupsName)
            } catch (error) {
                setListGroups([])
            }
        }
        fetchGroups()
    }, []);





    return (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Box className={classes.boxHeader}>
                    <Box className={classes.boxButton}>
                        <Button
                            startIcon={<ArrowBackIcon />}
                            variant="contained"
                            onClick={() => navigate('/bo/users')}
                        >
                            {t("back")}
                        </Button>
                    </Box>
                </Box>
                <Formik
                    initialValues={{
                        name: "",
                        description: "",
                        groups: []
                    }}
                    onSubmit={async (values, actions) => {
                        try {
                            const postData = {
                                input: {
                                    email: values.email,
                                    name: values.name,
                                    groups: values.groups
                                }
                            }
                            await GroupAndUser.createUser(postData)
                            navigate('users')
                        } catch (error) {
                            console.log(error)
                        }
                    }}
                >
                    {(props) => (
                        <Paper elevation={1} className={classes.content}>
                            <form onSubmit={props.handleSubmit} style={{ width: '100%' }}>
                                <TextField
                                    id="email"
                                    type="text"
                                    label={t("email")}
                                    placeholder={t("email")}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <EmailIcon className={classes.icons} />
                                        ),
                                    }}
                                    onChange={props.handleChange}
                                    required
                                />
                                <TextField
                                    id="name"
                                    type="text"
                                    label={t("name")}
                                    placeholder={t("name")}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <TextFields className={classes.icons} />
                                        ),
                                    }}
                                    onChange={props.handleChange}
                                    required
                                />

                                <Box style={{margin: '10px 0 20px'}}>
                                    <MultipleSelect
                                        id="groups"
                                        label={t('groups')}
                                        options={listGroups}
                                        values={props.values.groups}
                                        onChange={value => {
                                            props.setFieldValue("groups", value)
                                        }}
                                    />
                                </Box>

                                <SubmitButton
                                    isSubmitting={props.isSubmitting}
                                    label={"create user"}
                                    style={{ width: "100%" }}
                                />
                            </form>
                        </Paper>
                    )}
                </Formik>
            </Container>
        </Box>
    );
};

export default Create;

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#FFF",
        width: "100%",
        margin: "10px 0px 30px",
    },
    boxHeader: {
        marginBottom: "40px",
        display: "flex",
        alignItems: "center",
    },
    boxButton: {
        marginLeft: "auto",
    },
    content: {
        padding: 32,
    },
    icons: {
        marginRight: "10px",
        color: theme.palette.primary.main
    },
    textArea: {
        "& .MuiOutlinedInput-root": {
            width: '100% !important',
            height: '100% !important',
            alignItems: 'flex-start'
        },
    },
}))


