import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import { useNavigate } from "react-router";
import { makeStyles, Box, Container, Button, Paper, TextField } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import TextFields from '@material-ui/icons/TextFields';
import SubmitButton from "../../../../components/Backoffice/SubmitButton";

import { ServiceContext } from '../../../../context/services/service-context';



const Create = () => {
    const classes = useStyles();

    const { t } = useTranslation();

    const navigate = useNavigate();

    const { GroupAndUser } = useContext(ServiceContext);


    return (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Box className={classes.boxHeader}>
                    <Box className={classes.boxButton}>
                        <Button
                            startIcon={<ArrowBackIcon />}
                            variant="contained"
                            onClick={() => navigate(-1)}
                        >
                            {t("back")}
                        </Button>
                    </Box>
                </Box>
                <Formik
                    initialValues={{
                        name: "",
                        description: ""
                    }}
                    onSubmit={async (values, actions) => {
                        try {
                            console.log(values)
                            const postData = {
                                input: values
                            }
                            await GroupAndUser.createGroup(postData)
                            navigate(-1)
                        } catch (error) {
                            console.log(error)
                        }
                    }}
                >
                    {(props) => (
                        <Paper elevation={1} className={classes.content}>
                            <form onSubmit={props.handleSubmit} style={{ width: '100%' }}>
                                <TextField
                                    id="name"
                                    type="text"
                                    label={t("name")}
                                    placeholder={t("name")}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <TextFields className={classes.icons} />
                                        ),
                                    }}
                                    onChange={props.handleChange}
                                    required
                                />
                                <TextField
                                    id="description"
                                    type="text"
                                    label={t("description")}
                                    rows={4}
                                    multiline={true}
                                    placeholder={t("description")}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <TextFields className={classes.icons} />
                                        ),
                                    }}
                                    classes={{
                                        root: classes.textArea,
                                    }}
                                    onChange={props.handleChange}
                                    required
                                />
                                <SubmitButton
                                    isSubmitting={props.isSubmitting}
                                    label={"create group"}
                                    style={{ width: "100%" }}
                                />
                            </form>
                        </Paper>
                    )}
                </Formik>
            </Container>
        </Box>
    );
};

export default Create;

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#FFF",
        width: "100%",
        margin: "10px 0px 30px",
    },
    boxHeader: {
        marginBottom: "40px",
        display: "flex",
        alignItems: "center",
    },
    boxButton: {
        marginLeft: "auto",
    },
    content: {
        padding: 32,
    },
    icons: {
        marginRight: "10px",
        color: theme.palette.primary.main
    },
    textArea: {
        "& .MuiOutlinedInput-root": {
            width: '100% !important',
            height: '100% !important',
            alignItems: 'flex-start'
        },
    },
}))


