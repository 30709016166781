import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { makeStyles, Box, Tooltip, IconButton, Container, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Table from '../../../../components/Backoffice/Table';

import { ServiceContext } from '../../../../context/services/service-context';


const ColDefStyle = {
    headerAlign: "center" ,
    align: "center",
};


const GroupView = () => {

    const classes = useStyles();

    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    const { GroupAndUser } = useContext(ServiceContext);

    const [loading, setLoading] = useState(true)
    const [listGroups, setListGroups] = useState([]);
    const [dataTable, setDataTable] = useState([]);


    const columns = [
        { headerName: t("id"), field: "id", hide: true, ...ColDefStyle},
        { headerName: t("name"), field: "name", flex: 0.3, ...ColDefStyle },
        { headerName: t("description"), field: "description", flex: 1, ...ColDefStyle },
        {
            headerName: t("actions"),
            hide: true,
            field: "action",
            flex: 0.3,
            ...ColDefStyle,
            renderCell: (params) => (
                <Box className={classes.actionsBox}>
                    <Tooltip title={`${t("edit")}`}>
                        <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={() => {
                                navigate(`edit/${params.row.name}`, {
                                    replace: true,
                                    state: params.row.id
                                })
                            }}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Box>
            ),
        },

    ];

    useEffect(() => {

        const fetchGroups = async () => {
            try {
                const result = await GroupAndUser.listGroup();
                const _groups = result?.data?.listGroup?.items
                setListGroups(_groups)
            } catch (error) {
                setLoading(false)
            }
        }
        fetchGroups()
    }, []);

   

    useEffect(() => {
        const processData = () => {
            setLoading(true)
            const _data = listGroups.map((item) => {
                return {
                        id: item.groupName,
                        name: item.groupName,
                        description: item.description
                    };
                })
            setDataTable(_data);
            setLoading(false)
        };
        processData();
    }, [listGroups]);



    return (
         <Box className={classes.root}>
            <Container maxWidth="lg">
                <Box className={classes.boxHeader}>
                    <Box className={classes.boxButton}>
                        <Button
                            startIcon={<AddIcon />}
                            variant="contained"
                            onClick={() => navigate("create")}
                        >
                            {t("create")}
                        </Button>
                    </Box>
                </Box>
                <Box style={{ display: "flex" }}>
                    <Table
                        columns={columns}
                        data={dataTable}
                        loading={loading}
                    />
                </Box>
              
            </Container>
        </Box>

    );
}

export default GroupView;


const useStyles = makeStyles({
    root: {
        backgroundColor: "#FFF",
        width: "100%",
        margin: "10px 0px 30px",
    },
    boxHeader: {
        marginBottom: "40px",
        display: "flex",
        alignItems: "center",
    },
    boxButton: {
        marginLeft: "auto",
    },
    content: {
        padding: 32,
    },
    icons: {
        marginRight: "10px",
        color: "#8a307f",
    }
});
