import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

import { useTranslation } from 'react-i18next';

import Styles from './style';

const useStyles = makeStyles(Styles);


const notificationStatus = {
    state: { open: false, type: null, title: null, message: '', timer: 0 },
    setState(value) {
        this.state = value;
        this.setters.forEach(setter => setter(this.state));
    },
    setters: []
};

export const useNotifications = () => {

    const [state, set] = useState(notificationStatus.state);
    if (!notificationStatus.setters.includes(set)) {
        notificationStatus.setters.push(set);
    }

    useEffect(() => () => {
        notificationStatus.setters = notificationStatus.setters.filter(setter => setter !== set)
    }, []);

    /**
     * Open snackbar handler
     */
    function open() {
        notificationStatus.setState({ ...state, open: true });
    }

    /**
     * Close snackbar handler
     */
    function close(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        notificationStatus.setState({ ...state, open: false });
    }

    /**
     * Create a new notificaiton
     * @param {string} type type of the alert. [info, warning, success, error]
     * @param {string} message message of the alert.
     * @param {string} title (optional) title of the alert message.
     * @param {number} timer (optional) value (miliseconds) for the autohide property of the snackbar.
     */
    function triggerNotification(type, message, title = null, timer = 7000) {
        console.log('triggerNotification')
        notificationStatus.setState({ open: true, type: type, message: message, title: title, timer: timer });
    }

    return { state, open, close, triggerNotification };
}

notificationStatus.setState = notificationStatus.setState.bind(notificationStatus);

const Notifications = () => {

    console.log('Notifications')

    const classes = useStyles();
    const { t } = useTranslation();
    const { state, close } = useNotifications();

    return (
        <Snackbar open={state.open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={7000} onClose={close}>
            <Alert severity={state.type} onClose={close} className={classes.root}>
                {state.title != null ? <AlertTitle><strong>{t(state.title)}</strong></AlertTitle> : null}
                {t(state.message)}
            </Alert>
        </Snackbar>
    )
};

export default Notifications;