import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CategoryRow from '../../../../../components/Shared/CategoryRow';
import ContentCard from '../../../../../components/Shared/ContentCard';
import CardPlaceholder from '../../../../../components/Shared/CardPlaceholder';


import Assets from '../../../../../assets'


import Styles from './style.js';



const DATA_DUMMY = [
    {
        title: "Todo title 1",
        subtitle: "Subtitle 1 todo goes here",
        description: "Todo description 1",
        isOnline: true,
        thumbnail: Assets.Images.todoImage,
    },
    {
        title: "Todo title 2",
        subtitle: "Subtitle 2 todo goes here",
        description: "Todo description 2",
        isOnline: true,
        thumbnail: Assets.Images.todoImage,
    }
]



const useStyles = makeStyles(Styles);

const DemosList = () => {

    const classes = useStyles();
    const { t } = useTranslation();


    useEffect(() => {
    }, []);

 
   

    return (
        <Grid>
            {
                DATA_DUMMY ?
                    DATA_DUMMY.length > 0 ?
                        <CategoryRow hideHeader={true} expand={true} >
                            <CardPlaceholder />
                            {DATA_DUMMY.map((item, index) => {
                                return <ContentCard data={item} />
                            })}
                        </CategoryRow>
                        :
                        <div className={classes.progressContainer}>
                            <span
                                className={classes.label}
                                style={{ justifyContent: 'center' }}>
                                {t('demos-list-empty-message')}
                            </span>
                        </div>
                    :
                    <div className={classes.progressContainer}>
                        <CircularProgress size={50} />
                    </div>
            }
        </Grid >
    );
}

export default DemosList;