import palette from '../palette';


const MuiDialog = {
  paper: {
    padding: 0,
    alignItems: 'stretch',
      "& .MuiDialogTitle-root": {
        backgroundColor: palette.primary.dark,
        color: palette.white,
        "& h2": {
          color: palette.white,
          margin: '0 20px'
        },
      },
      "& .MuiDialogContent-root": {
        margin: '20px'
      },
      "& .MuiDialogActions-root": {
        padding: '20px !important',
        marginLeft: 'auto'
      }
    }
};

export default MuiDialog;

