import React, { useEffect, useState, useContext } from 'react';
import moment from "moment";
import 'moment/locale/pt';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { makeStyles, Box, Tooltip, IconButton, Container, Button, FormControlLabel, Switch, Chip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Table from '../../../../components/Backoffice/Table';

import { ServiceContext } from '../../../../context/services/service-context';
import { useConfirm } from '../../../../context/Confirm';


const ColDefStyle = {
    headerAlign: "center",
    align: "center",
};


const GroupView = () => {

    const classes = useStyles();

    const navigate = useNavigate();

    const { t, i18n } = useTranslation();

    moment.locale(i18n.language)

    const { confirm } = useConfirm();

    const { GroupAndUser } = useContext(ServiceContext);

    const [loading, setLoading] = useState(true)
    const [listUsers, setListUsers] = useState([]);
    const [dataTable, setDataTable] = useState([]);

    const columns = [
        { headerName: t("id"), field: "id", hide: true },
        { headerName: t("username"), field: "username", hide: true },
        { headerName: t("name"), field: "name", hide: false, flex: 1, ...ColDefStyle },
        { headerName: t("enabled"), field: "enabled", hide: true },
        { headerName: t("email"), field: "email", flex: 1, ...ColDefStyle },
        {
            headerName: t("group"), field: "groups", flex: 1, ...ColDefStyle, renderCell: (params) => {
                return (
                    <Box className={classes.boxChipInput}>
                        {params.row.groups?.map(it => (
                            <Chip
                                key={it}
                                size="small"
                                className={classes.chip}
                                label={it}
                                onDelete={() => onRemoveGroup(params.row, it)}
                            />
                        ))}

                    </Box>
                )
            }
        },
        { headerName: t("create date"), field: "createAt", flex: 0.5, ...ColDefStyle },
        { headerName: t("status"), field: "status", hide: true, flex: 1, ...ColDefStyle },
        {
            headerName: t("enabled"), field: "enabledRender", hide: false, flex: 0.2, ...ColDefStyle, renderCell: (params) => (
                <FormControlLabel
                    control={<Switch
                        //disabled={loading}
                        size="small"
                        checked={params.row.enabled}
                        onChange={(event) => onChangeStatus(params.row)} />}
                />
            )
        },
        {
            headerName: t("actions"),
            field: "action",
            flex: 0.2,
            ...ColDefStyle,
            renderCell: (params) => (
                <Box className={classes.actionsBox}>
                    <Tooltip title={`${t("edit")}`}>
                        <IconButton
                            aria-label="edit"
                            size="small"
                            onClick={() => {
                                navigate(`edit/${params.row.name}`, {
                                    replace: true,
                                    state: params.row.id
                                })
                            }}
                        >
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    {/*
                     <Tooltip title={`${t("delete")}`}>
                        <IconButton
                            disabled={params.row.enabled}
                            aria-label="delete"
                            size="small"
                            onClick={() => {
                                console.log('delete')
                            }}
                        >
                            <DeleteIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                    */}

                </Box>
            ),
        },

    ];


    const onRemoveGroup = async (data, group) => {
        try {
            confirm(t("remove group title"), t("remove group text")).then(
                async (confirmed) => {
                    if (confirmed) {
                        setLoading(true)
                        const postData = {
                            input: {
                                groupName: group,
                                username: data.username,
                                groups: data.groups
                            }
                        }
                        await GroupAndUser.removeUserFromGroup(postData);
                        fetchUsers()
                    }
                }
            );

        } catch (error) {
            setLoading(false)
        }
    }


    const fetchUsers = async () => {
        try {
            setLoading(true)
            const result = await GroupAndUser.listUser();
            const _users = result?.data?.listUser?.items
            setListUsers(_users)
        } catch (error) {
            setLoading(false)
        }
    }

    const onChangeStatus = async (data) => {
        try {
            setLoading(true)
            const postData = {
                input: {
                    name: data.name,
                    groups: data.groups,
                    username: data.username,
                    enabled: !data.enabled
                }
            }
            await GroupAndUser.updateUser(postData)
            fetchUsers()
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        fetchUsers()
    }, []);

    useEffect(() => {
        const processData = async () => {
            setLoading(true)
            const _data = listUsers.map(item => {
                return {
                    id: item.username,
                    username: item.username,
                    name: item.name,
                    createAt: moment(new Date(item.createAt)).format("L"),
                    status: t(item.status),
                    enabled: item.enabled,
                    enabledRender: item.enabled,
                    email: item.email,
                    groups: item.groups?.split(',')
                };
            })
            setDataTable(_data);
            setLoading(false)
        };
        processData();
    }, [listUsers, t]);


    return (
        <Box className={classes.root}>
            <Container maxWidth="lg">
                <Box className={classes.boxHeader}>
                    <Box className={classes.boxButton}>
                        <Button
                            startIcon={<AddIcon />}
                            variant="contained"
                            onClick={() => navigate("create")}
                        >
                            {t("create")}
                        </Button>
                    </Box>
                </Box>
                <Box style={{ display: "flex" }}>
                    <Table
                        columns={columns}
                        data={dataTable}
                        loading={loading}
                    />
                </Box>
            </Container>
        </Box>

    );
}

export default GroupView;


const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: "#FFF",
        width: "100%",
        margin: "10px 0px 30px",
    },
    boxHeader: {
        marginBottom: "40px",
        display: "flex",
        alignItems: "center",
    },
    boxButton: {
        marginLeft: "auto",
    },
    content: {
        padding: 32,
    },
    boxChipInput: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap"
    },
    chip: {
        margin: '5px'
    }
}));
