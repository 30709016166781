import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Switch, FormControlLabel  } from '@material-ui/core';
import { CreateOutlined, DeleteOutlined, Star } from '@material-ui/icons';
import Assets from '../../../assets';

import Styles from './style.js';

const useStyles = makeStyles(Styles);

const ContentCard = ({data}) => {

    const { t, i18n } = useTranslation();
    const classes = useStyles();
    const [state, setState] = useState({ isLoading: false });

    const onEditHandler = async (data) => {
        await data.actions.onEdit(data);
    }

    const onDeleteHandler = async (data) => {
        setState({ ...state, isLoading: true });
        //await data.actions.onDelete(data);
        setState({ ...state, isLoading: false });
    }

    const onChangeOnlinehandler = async (event) => {
        setState({ ...state, isLoading: true });
        const _data = {
            ...data,
            online: event.target.checked
        }
        //await data.actions.onChangeOnline(_data);
        setState({ ...state, isLoading: false });
    }

    const thumbnailFallback = (data) => {
        data.target.src = Assets.Images.error_image;
    }

    return (
        <div className={classes.root}>
            <div className={classes.buttons} style={{position: 'absolute', top: '7px', right: '7px', display: data?.favorite ? 'flex' : 'none'}}>
                <Star className={classes.icons} />
            </div>
            <div className={classes.content}>
                <div className={classes.thumbnail}><img src={data?.thumbnail ? data?.thumbnail : Assets.Images.empty} onError={thumbnailFallback} alt='' /></div>
                <div className={classes.title}><p>{data.title}</p></div>
                <div className={classes.subtitle} style={{ flex: data.subtitle ? 0.3 : 0 }}><p>{data.subtitle}</p></div>
                <div className={classes.description} style={{ '-webkit-line-clamp': data.subtitle ? 3 : 4, flex: data.subtitle ? 0.5 : 0.6 }}><p >{data.description}</p></div>
                {data.online && (
                    <div className={classes.toogleBox}>
                        <FormControlLabel control={
                            <Switch
                                color="primary"
                                checked={data.online?.isOnline}
                                onChange={(event) => onChangeOnlinehandler(event)}
                            />
                        }
                            label={t('todos-create-input-online')}
                        />
                    </div>
                )}
            </div>
            <div className={classes.overlay} style={{ display: state.isLoading ? 'none' : '' }}>
                {!!data?.actions?.onEdit && (
                    <div className={classes.buttons} onClick={() => onEditHandler(data)}>
                        <CreateOutlined className={classes.icons} />
                    </div>
                )}
                {!!data?.actions?.onDelete && (
                    <div className={classes.buttons} onClick={() => onDeleteHandler(data)}>
                        <DeleteOutlined className={classes.icons} />
                    </div>
                )}
            </div>
            <div className={classes.overlay} style={{ display: state.isLoading ? 'flex' : 'none' }}>
                <CircularProgress size={50} className={classes.progress} />
            </div>
        </div>
    );
}
export default ContentCard;
