import React, { useEffect, useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab } from '@material-ui/core';

import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Structer } from './config';
import Styles from './style';

const useStyles = makeStyles(Styles);

const navBarStatus = {
    state: { index: 0 },
    setState(value) {
        this.state = value;
        this.setters.forEach(setter => setter(this.state));
    },
    setters: []
};
export const useNavBar = () => {

    const [state, set] = useState(navBarStatus.state);
    if (!navBarStatus.setters.includes(set)) {
        navBarStatus.setters.push(set);
    }

    useEffect(() => () => {
        navBarStatus.setters = navBarStatus.setters.filter(setter => setter !== set)
    }, []);

    function goToTab(index) {
        navBarStatus.setState({ ...state, index: index });
    }

    return { state, goToTab };
}

navBarStatus.setState = navBarStatus.setState.bind(navBarStatus);

const NewsView = () => {

    const classes = useStyles();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { viewId } = useParams();
    const { state, goToTab } = useNavBar();
    const [structer, setStructer] = useState({ index: 0, component: null });

    useEffect(() => {
        onLoadPage();
        return (() => { goToTab(0); })
    }, [])

    useEffect(() => {
        handleChange(null, state.index);
    }, [state.index]);

    const onLoadPage = () => {
        const viewIndex = Structer.sections.find(item => item.id == viewId);
        setStructer({ index: viewIndex ? viewIndex.index : 0, component: viewIndex ? viewIndex.component : Structer.sections[0].component });
    }

    const handleChange = (event, newValue) => {
        const viewIndex = Structer.sections.find(item => item.index == newValue);
        setStructer({ index: viewIndex.index, component: viewIndex.component });
        navigate(viewIndex.path);
        goToTab(viewIndex.index);
    };

    function a11yProps(index) {
        return {
            id: `scrollable-auto-tab-${index}`,
            'aria-controls': `scrollable-auto-tabpanel-${index}`,
        };
    }

    return (
        <div className={classes.root}>
            <div className={classes.title}>{t(Structer.title)}</div>
            <div className={classes.navbar}>
                {
                    Structer.sections.length > 0 ?
                        <Tabs
                            value={structer.index}
                            onChange={handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            {
                                Structer.sections.map(prop => {
                                    return (
                                        <Tab key={prop.index} label={t(prop.label)} icon={prop.icon} {...a11yProps(prop.index)} />
                                    )
                                })
                            }
                        </Tabs>
                        :
                        null
                }
            </div >
            <div className={classes.grid}>
                {
                    structer.component
                }
            </div>
        </div>
    );
}

export default NewsView