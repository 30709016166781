import React, { useEffect, useState, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import { useNavigate, Link as RouterLink } from 'react-router-dom';

import Styles from './style';

const useStyles = makeStyles(Styles);

const DashboardAppView = () => {


    const classes = useStyles();
    const navigate = useNavigate();

    return (
        <div className={classes.root}>
            <h1>This is the dashboard app</h1>
        </div>
    );
}

export default DashboardAppView;