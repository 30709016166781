import React from 'react';
import { useTranslation } from 'react-i18next';

//Material
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress } from "@material-ui/core";




const Loading = () => {

    const { t } = useTranslation();

    const classes = useStyles();


    return (

        <Box className={classes.root}>
            <CircularProgress />
            <h4>{t('loading')}...</h4>
        </Box>
    )
}

export default Loading;



const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
    }
});
