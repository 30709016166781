const Styles = theme => ({
    root: {
        flex: '1',
        maxWidth: '1232px',
        height: 'auto',
    },
    title: {
        width: '100%',
        display: 'flex',
        padding: '10px 0px ',
        ...theme.typography.title_h1_reg,
    },
    subtitle: {
        width: '100%',
        height: 'auto',
        fontSize: '27px !important',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        ...theme.typography.subtitle,
    },
    navbar: {
        width: '100%',
        padding: '20px 0px 40px 0px'
    },
    grid: {
        flexGrow: 1
    },
    form: {
        width: '100%'
    },
    icons: {
        fontSize: '19px',
        marginRight: '10px',
        color: '#B0191A'
    },
})

export default Styles;